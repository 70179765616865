import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { StaticImage } from 'gatsby-plugin-image';

import { Layout, Seo } from 'components';
import ContentBlock from 'components/content-block';
import Bolt from 'components/bolts';

import copayCard from 'images/patient/pages/sprix-direct/sprix-direct-copay-card.png';
import phone from 'images/patient/pages/sprix-direct/sprix-direct-phone-icon.png';
import boxes from 'images/patient/pages/sprix-direct/sprix-direct-boxes.png';

// import page style
import './sprix-direct.scss';

const pageTitle = 'About SPRIX';
const pageDescription = '';

const Medication = () => {
  return (
    <>
      <Row>
        <Col xs={12}>
          <h2 className="blue-headline">
            SPRIX® (ketorolac tromethamine) is{' '}
            <strong>exclusively available</strong>
          </h2>
          <h3 className="sub-headline no-padding-top">
            through <strong>SPRIX DIRECT</strong>
          </h3>
          <h3 className="sub-headline sub-headline-pad no-padding-top no-padding-bottom normal-lh">
            <strong>Your medication will be delivered right to your </strong>
          </h3>
          <h3 className="sub-headline no-padding-top normal-lh">
            <strong>door through SPRIX DIRECT.</strong>
          </h3>
          <Bolt color="ltBlue" classes="pain-guy-left-top" small />
          <Bolt color="blue" classes="pain-guy-left-copay" small />
          <Bolt color="blue" classes="pain-guy-right-copay" small />
          <Bolt color="gray" classes="pain-guy-right-knee" small />
          <Bolt color="ltBlue" classes="pain-guy-back" small />
          <img className="copay-card" src={copayCard} />
          <p className="benefits-copy">
            <strong>
              SPRIX DIRECT provides you with the following benefits
            </strong>
            :
          </p>
          <ul className="large-list no-margin-left">
            <li>Product delivered directly to your home</li>
            <li>Instructions for Use included with your shipment</li>
            <li>
              Automatic $0 co-pay for eligible patients with commercial
              insurance
            </li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={2} className="center-mobile">
          <StaticImage
            src="../../images/patient/pages/sprix-direct/sprix-direct-phone-icon.png"
            alt="Mobile"
            placeholder="none"
          />
        </Col>
        <Col xs={12} md={10}>
          <p className="phone-copy">
            To help get your medicine faster, call <strong>SPRIX DIRECT</strong>{' '}
            at{' '}
            <nobr>
              <strong>1-844-97-SPRIX (1-844-977-7749)</strong>
            </nobr>
            , Monday–Friday, from 9:00 <span className="small-caps">AM</span> to
            8:00 <span className="small-caps">PM</span> ET, and Saturday, from
            11:00 <span className="small-caps">AM</span> to 3:00{' '}
            <span className="small-caps">PM</span> ET, and let us know your
            healthcare provider has prescribed you SPRIX.
          </p>
        </Col>
      </Row>
    </>
  );
};

const SprixDirect = () => {
  return (
    <>
      <Row>
        <Col xs={12}>
          <h2 className="no-padding-bottom">
            <strong className="main-color">SPRIX DIRECT</strong>
          </h2>
        </Col>
      </Row>
      <h2 className="blue-headline">
        You will <strong>receive</strong>
      </h2>
      <Row middle="xs" className="standard-top-spacing">
        <Col xs={11} md={7}>
          <img className="boxes" src={boxes} />
        </Col>
        <Col xs={11} md={5}>
          <ul className="large-list no-margin-left">
            <li>Carton of SPRIX (5 bottles)</li>
            <li>Medication Guide</li>
            <li>Instructions for Use</li>
          </ul>
        </Col>
      </Row>
      <Row px={2}>
        <Col>
          <p className="benefits-copy">
            <strong>How should I store SPRIX?</strong>
          </p>
          <ul className="large-list no-margin-left">
            <li>
              Store unopened SPRIX bottles between 36°F to 46°F (2°C to 8°C)
            </li>
            <li>Keep opened bottles of SPRIX at room temperature</li>
            <li>Keep SPRIX out of direct sunlight</li>
            <li>Do not freeze SPRIX</li>
            <li>
              SPRIX does not contain a preservative. Throw away each SPRIX
              bottle within 24 hours of taking your first dose, even if the
              bottle still contains unused medicine
            </li>
          </ul>
        </Col>
      </Row>
    </>
  );
};

const SprixDirectPage = () => {
  return (
    <Layout indication="patient" className="patient-sprix-direct">
      <Seo pageTitle="Patient About Page" pageTitle={pageTitle} />
      <div className="body-content-container">
        <div className="body-content gutter-all">
          <ContentBlock>
            <Medication />
            <Row>
              <Col xs={12}>
                <p className="phone-copy">
                  Within 24 hours of your healthcare provider prescribing SPRIX,
                  you will receive a confirmation call from{' '}
                  <strong>SPRIX DIRECT</strong> with a (410) or (443) area code
                  to authorize your prescription.
                </p>
              </Col>
            </Row>
            <SprixDirect />
          </ContentBlock>
        </div>
      </div>
    </Layout>
  );
};

export default SprixDirectPage;
